import {
    getQueryString,
    delParams
} from './url'
import {
    baseUrl
} from '../api/http'

// 微信授权方法
export const alipayLogin = (institutionId) => {
    let oldCode = getQueryString('code');
    let URL = '';
    if (oldCode) {
        URL = delParams(['code', 'state']); //删除地址中的指定参数
    } else {
        URL = window.location.href;
    }
    let redirectUri = btoa(URL);
    let requestUrl = `${baseUrl}api/third/auth/aliAuth?institutionId=${institutionId}&url=${redirectUri}`;
    //   判断是pc还是手机
    if (isMobile()) {
        // 微信授权登录
        location.href = requestUrl;
    } else {
        // 微信扫码登录
        window.location.href = requestUrl;
    }
}

export const getWxCode = () => {
    return getQueryString('code')
}

function isMobile() {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    return flag
}