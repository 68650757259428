<template>
  <div class="bindwx">
    <div class="wx-list">
      <div class="title">绑定支付宝</div>
      <div class="list flex-x-between flex-xy-center">
        <p>绑定状态</p>
        <div class="line28" v-if="info">已绑定</div>
        <div class="line28" v-else>未绑定</div>
      </div>
      <div class="list flex-x-between flex-xy-center border-none" v-if="info">
        <p>绑定信息</p>
        <div class="flex-x-start flex-xy-center">
          <img :src="info.avatarUrl" alt="" />
          <span>{{ info.nickname }}</span>
        </div>
      </div>
    </div>
    <van-button round block native-type="submit" class="submit-btn" @click="reBindAlipay" v-if="info">重新绑定
    </van-button>
    <van-button round block native-type="submit" class="submit-btn" @click="bindAlipay" v-else>绑定支付宝</van-button>
    <div v-show="authUrl" class="qrcode-wrapper">
      <div id="qrcode" ref="qrcode" class="qrcode"></div>
      <div class="tip">请使用支付宝扫码{{ info ? '重新' : '' }}绑定</div>
    </div>
  </div>
</template>

<script>
import { alipayAuthInfo, getAlipayAuthQrCode } from "api/common";
import { alipayLogin } from "utils/alipayLogin.js";
import QRCode from "qrcodejs2";
export default {
  name: "BindAlipay",
  data() {
    return {
      info: {},
      merchant: {},
      authUrl: ''
    };
  },
  filters: {
    status(val) {
      if (val === 1) {
        return "已绑定";
      } else {
        return "未绑定";
      }
    },
  },
  created() {
    this.merchant = JSON.parse(localStorage.getItem("payInfo"))
    this.getAuthInfo();
    this.getAuthQrCode();
    let code = this.$route.query.code;
    if (code) {
      this.doAlipayAuth(code);
    }
  },
  methods: {
    // 查询是否授权
    getAuthInfo() {
      alipayAuthInfo().then((res) => {
        if (res.code === 200) {
          console.log(res);
          this.info = res.data;
          localStorage.setItem("alipayAuthInfo", JSON.stringify(res.data));
        }
      });
    },
    getAuthQrCode() {
      getAlipayAuthQrCode().then(res => {
        if (res.code === 200) {
          this.authUrl = res.data

          this.$nextTick(() => {
            this.generateQrcode();
          });
        }
      })
    },
    generateQrcode() {
      new QRCode("qrcode", {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: this.authUrl, // 设置二维码内容或跳转地址
      })
    },
    reBindAlipay() {
      localStorage.removeItem("alipayAuthInfo");
      this.bindAlipay();
    },
    bindAlipay() {
      alipayLogin(this.merchant.institutionId);
    },
    doAlipayAuth(code) {
      let params = { "code": code };
      alipayAuth(params).then((res) => {
        if (res.code == 200) {
          this.getAuthInfo();
        } else {
          this.$toast.fail(res.msg);
        }
      }).catch(err => {
        this.$toast.fail(err.msg);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bindwx {
  font-size: 14px;
  margin: 15px;

  .wx-list {
    padding: 0 15px 15px 15px;
    background: #fff;

    .title {
      font-size: 16px;
      color: #1e2431;
      padding: 20px 0;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1);
    }

    .list {
      padding: 15px 0;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1);

      .line28 {
        height: 28px;
        line-height: 28px;
      }

      p {
        color: rgba(30, 36, 49, 0.6);
      }

      span {
        color: #1e2431;
      }

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }

    .border-none {
      border: none;
      padding-bottom: 0;
    }
  }

  .submit-btn {
    margin-top: 16px;
    height: 36px;
  }
}

.qrcode-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  .qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
  }
  .tip {
    margin-top: 20px;
  }
}
</style>
